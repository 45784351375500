@import url('https://fonts.googleapis.com/css?family=Montserrat');

/* MAIN COLOR */
/* SECONDARY COLOR */
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$primary: #52371b; /* MAIN COLOR */
$secondary: #52371b; /* SECONDARY COLOR */
$gray: #666;
$blk: #111;
$wht: #f8f8f8;
$links: #444;

.body {
	font-family: 'Montserrat', sans-serif;
}

/*.flash {
  display: none;
}*/

nav {
  z-index: 1000;
}
.navbar-default{
	background: black;
	border: none;
}
.navbar {
  margin: 0;
  .navbar-nav > li > a {
    text-align: center;
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    &:hover, &:focus {
      background: #52371b;
      color: #fff;
    }
  }
  margin-bottom: 0;
}

.navbar-toggle {
  margin: 20px 15px 8px 0px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.top-pad {
  margin-top: 120px;
}

@media (max-width: 388px) {
  .navbar-toggle {
    margin-top: 14px;
  }
}

p {
	font-family: 'Montserrat', sans-serif;
}

/** LOGIN FORM **/

.modal-dialog {
  max-width: 350px;
  text-align: center;
  margin: 6em auto;
  .close {
    display: none;
  }
  .modal-content {
    color: #52371b;
    h2 {
      text-align: center;
    }
  }
  input {
    border: none;
    border-bottom: 1px solid #072c4e;
    text-align: center;
  }
  button {
    background: transparent;
    color: #fafafa;
    display: block;
    border: none;
    padding: 1em 2em;
    margin: 0 auto;
    &:hover {
      background: transparent;
      color: #fff;
      box-shadow: none;
      text-shadow: 0px 0px 3px #333;
      border: none;
    }
  }
  input[type="submit"] {
    text-align: center;
    margin: 0 auto;
    border: 1px solid #52371b;
    border-radius: 0px;
    background: #52371b;
    color: #fafafa;
    padding: 0.5em 2em;
    display: block;
    width: 75%;
    &:hover {
      background: #147ada;
      color: white;
      box-shadow: 0px 0px 3px #4d4d4d;
    }
  }
}

@media (max-width: 767px) {
  .top-pad {
    margin-top: 80px;
  }
  .navbar .navbar-nav > li > a {
    &:hover, &:focus {
      background: transparent;
      color: #333;
    }
    height: 22px;
    display: inline-block;
  }
  .logo {
    max-height: 70px;
  }
}

.modal-header, .modal-footer {
  background: #52371b;
  color: #fafafa;
}

input#username {
  margin-bottom: 20px;
}

/** END LOGIN FORM **/

section {
  padding: 50px 0;
}

.hero-banner {
  background: url("../img/bg1.jpg") no-repeat (top / cover);
  margin-top: 100px;
  h1 {
    font-size: 2.5em;
    font-weight: bold;
    color: $blk;
  }
  p {
    font-weight: 400;
    font-size: 1.7em;
    color: $blk;
  }
  .cta-text {
    margin-top: 10%;
  }
}

.cta-box {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c3116+1,fce69c+100 */
	background: #4c3116; /* Old browsers */
	background: -moz-linear-gradient(-45deg, #4c3116 1%, #fce69c 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, #4c3116 1%,#fce69c 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, #4c3116 1%,#fce69c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c3116', endColorstr='#fce69c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  h2 {
    font-size: 2em;
    color: $blk;
    text-transform: uppercase;
  }
  .text-italic {
    font-weight: bold;
    font-style: italic;
    font-size: 4em;
    color: transparent;
    color: $blk;
    text-shadow: #fafafa 0px 0px 1px;
  }
  p {
    color: $blk;
    font-size: 1.5em;
    // font-weight: 200;
  }
}

.hero-image {
  background: url("../img/bg2.jpg") no-repeat (top / cover);
  padding: 200px 0;
  h1 {
    font-size: 2.5em;
    color: $blk;
    text-transform: uppercase;
    // letter-spacing: .25em;
  }
  p {
    font-size: 2em;
    color: $blk;
  }
}

.hero-image-2 {
  background: url("../img/bg3.jpg") no-repeat (top / cover);
  padding: 200px 0;
  h1 {
    font-size: 2.5em;
    color: $blk;
    text-transform: uppercase;
    // letter-spacing: .25em;
  }
  p {
    font-size: 2em;
    color: $blk;
  }
}

@media (max-width: 767px) {
  .hero-banner {
    margin-top: 70px;
  }
  .hero-image-2 h1 {
    font-size: 1.5em;
    line-height: 1em;
    // letter-spacing: 1px;
  }
  .hero-image {
    h1 {
      font-size: 1.5em;
      line-height: 1em;
      // letter-spacing: 1px;
    }
    padding: 50px 0;
  }
  .hero-image-2 {
    padding: 50px 0;
    p {
      font-size: 1.3em;
      color: $blk;
    }
  }
  .hero-image p {
    font-size: 1.3em;
    color: $blk;
  }
}

.cta {
  padding: 20px 40px;
  background: transparent;
  color: #fafafa;
  font-size: 1.2em;
  margin: 5px;
  border-radius: 0px;
  border: 1px solid #fafafa;
  &:hover {
    border-radius: 0px;
    color: #fafafa;
    background: #52371b;
    -webkit-transition: all 0.7s ease 0s;
    transition: all 0.7s ease 0s;
  }
}

@media (max-width: 767px) {
  .cta {
    padding: 10px 20px;
    font-size: 1.2em;
  }
  .cta-box .text-italic {
    font-size: 2em;
    color: $blk;
  }
}

footer {
  padding: 50px 0px 20px;
  background: #52371b;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c3116+1,fce69c+100 */
background: #4c3116; /* Old browsers */
background: -moz-linear-gradient(-45deg, #4c3116 1%, #fce69c 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, #4c3116 1%,#fce69c 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, #4c3116 1%,#fce69c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c3116', endColorstr='#fce69c',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  color: #fafafa;
  a {
    color: #fafafa;
    &:hover {
      color: white;
    }
  }
}

@media (max-width: 1024) {
  input {
    &#username, &#password {
      font-size: 16px;
    }
  }
}
